import { Home } from "@mui/icons-material";
import { Box, SvgIcon, Toolbar, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "src/app/hooks";
import { ChargersIcon, ContractsIcon, InvoicesIcon, LocationsIcon, TariffsIcon, UsersIcon } from "src/components/icons/ToolbarIcons";
import { ContractStatusEnum } from "src/enums";
import { AppRouteEnum, AppRouteSpace } from "src/utils/helper";
import { toolbarSx, linkSx } from "./UserNavigation.sx";

export const UserNavigation: FC = () => {
  const { authInfo } = useAppSelector((state) => state.common)
  const availableContractPage = authInfo.contract?.status === ContractStatusEnum.WAIT_FOR_SIGNATURE || authInfo.contract?.status === ContractStatusEnum.OPERATION || authInfo.contract?.status === ContractStatusEnum.INSTALLATION

  return (
    <Toolbar sx={toolbarSx}>
      
      {authInfo.chargePoint && availableContractPage && <Box
        sx={linkSx}
      >

        <NavLink
          to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.chargers, authInfo.chargePoint.id])}
        >
          <SvgIcon
            component={ChargersIcon}
            viewBox="0 0 20 20"
          />
          <Typography variant="subtitle1">
            <FormattedMessage id="charger" />
          </Typography>
        </NavLink>
      </Box>}
      {authInfo.contract?.status !== ContractStatusEnum.OPERATION && 
        <Box
          sx={linkSx}
        >
          <NavLink
            to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.welcome])}
          >
            <SvgIcon
              component={Home}
              viewBox="0 0 20 20"
            />
            <Typography variant="subtitle1">
              <FormattedMessage id="home" />
            </Typography>
          </NavLink>
        </Box>
      }
      {availableContractPage &&
      <>
        <Box
          sx={linkSx}
        >

          <NavLink
            to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.contracts, authInfo.contract.id])}
          >
            <SvgIcon
              component={ContractsIcon}
              viewBox="0 0 20 20"
            />
            <Typography variant="subtitle1">
              <FormattedMessage id="contract" />
            </Typography>
          </NavLink>
        </Box>
      </>
      }
      {/* <Box
        sx={linkSx}
      > 

      <NavLink
        to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.reports])}
        >
        <SvgIcon
          component={ReportsIcon}
          viewBox="0 0 20 20"
          />
        <Typography variant="subtitle1">
          <FormattedMessage id="reports" />
        </Typography>
      </NavLink>
          </Box> */}
      <Box
        sx={linkSx}
      >

        <NavLink
          to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.invoices])}
        >
          <SvgIcon
            component={InvoicesIcon}
            viewBox="0 0 20 20"
          />
          <Typography variant="subtitle1">
            <FormattedMessage id="invoices" />
          </Typography>
        </NavLink>
      </Box>
    </Toolbar>
  )
}
