import { Box, Button, Stack, Typography, debounce } from "@mui/material";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { PowerConsumptionProps } from "./PowerConsumption.types";
import { Area, AreaChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import {
  emptyStackSx,
  errorButtonMenuSx,
  errorButtonSx,
  rootSx, selectItemSx,
  selectWrapperSx,
  titleSx,
  tooltipWrapperSx
} from "./PowerConsumption.sx";
import { FormattedMessage, useIntl } from "react-intl";
import { transactionsApi } from "src/api";
import { DateSelect } from "../DateSelect";
import { DatesType } from "src/pages/Reports/Reports.types";
import dayjs from "dayjs";
import theme from "src/theme";
import { getNumberFromPx } from "../../../utils/helper";
import DashboardLayoutContext from "../../../layouts/DashboardLayout/DashboardLayout.context";
import AMPModal from "../AMPModal";
import { UploadConsumptionHistoryForm } from "../../forms/UploadConsumptionHistory";
import { emptyConsumptionPlaceholder } from "../../../utils/consts";

export const PowerConsumption: FC<PowerConsumptionProps> = ({
  chargerId,
  businessId,
  userId,
  dateFrom,
  annualWh,
  historicalDataLoaded = true
}) => {
  const [getTransactions, transactions] = transactionsApi.useLazyGetChartDataQuery()
  const intl = useIntl()
  const responsive = useContext(DashboardLayoutContext)
  const [modalOpen, setOpenModal] = useState(false)
  const [isOverLimitDisplaying, setIsOverLimitDisplaying] = useState<boolean>(false)
  const handleShowModal = (status: boolean) => () => setOpenModal(status)

  const [dates, setDates] = useState<DatesType>({
    allSessions: {
      from: 0,
      to: dayjs().valueOf()
    }
  })

  const handleHideOverlimit = () => {
    setIsOverLimitDisplaying(false)
  }

  const handleShowOverlimit = () => {
    setIsOverLimitDisplaying(true)
  }

  const transactionsList = useMemo(() => {
    if (!transactions.data) {
      return []
    }
    let currentValue = 0;
    const data = [...transactions.data]
      .filter(item => Boolean(item.wattsConsumed))
      .sort((a, b) => +a.date - +b.date)
      .map((item) => {
        if (item.wattsConsumed) {
          currentValue = Number((item.wattsConsumed / 1000 + currentValue).toFixed(2)) ?? 0
          return {
            date: (new Date(item.date)).toLocaleDateString('en-US'),
            "limit": currentValue > annualWh / 1000 && isOverLimitDisplaying  ? undefined : currentValue,
            "overLimit": currentValue > annualWh / 1000 && isOverLimitDisplaying ? currentValue : undefined
          }
        }
      })
      .filter(transaction => Boolean(transaction))
      .map(item => ({
        ...item,
        date: item && dayjs(item.date).format('DD/MM/YYYY')
      }))
    if(isOverLimitDisplaying) {
      const index = data.findIndex(item => !item.limit)
      if(index > -1 && data[index - 1]){
        data[index - 1].overLimit = data[index - 1]["limit"] ?? data[index - 1].overLimit  
      }
    }
  
    return data
  }, [dates, transactions, intl, isOverLimitDisplaying])

  const title = intl.formatMessage({ id: 'powerConsumption' })

  const getData = async () => {
    const data = { businessId, chargerId, stopFrom: dates.allSessions.from, stopTo: dates.allSessions.to, userId }
    if (!chargerId) {
      delete data.chargerId
    }
    if (!userId) {
      delete data.userId
    }
    await getTransactions(data)
  }

  useEffect(() => {
    getData()
  }, [dates])

  const dateFromConract = useMemo(() => {
    if(dayjs().diff(dateFrom, "year") < 1){
      return dateFrom
    }
    const date = new Date(dateFrom)
    date.setFullYear((new Date()).getFullYear())
    if(date.getTime() > (new Date()).getTime()){
      date.setFullYear((new Date()).getFullYear() - 1)
    }
    console.log(date)
    return date
  }, [dateFrom])

  const handleChangeDates = (
    newFrom: number,
    newTo: number,
    field: string
  ) => {
    return () => {
      setDisabled(true)
      setDates({
        [field]: {
          from: newFrom,
          to: newTo
        }
      })
    }
  }
  const [disabled, setDisabled] = useState<boolean>(false)

  const handleAnimationEnd = () => {
    setDisabled(false)
  }

  return (
    <Box sx={rootSx}>
      <Box sx={titleSx}>
        <Typography variant="subtitle1">{title}</Typography>
        <Box sx={selectWrapperSx}>
          {(!!transactionsList.length || historicalDataLoaded) &&
            <Box sx={selectItemSx}>
              <DateSelect
                handleTimeChange={handleChangeDates}
                handleHideOverlimit={handleHideOverlimit}
                handleShowOverlimit={handleShowOverlimit}
                field={Object.keys(dates)[0]}
                contractLimit={true}
                dateFrom={+dateFromConract}
                disabled={disabled}
              />
            </Box>
          }
          {!historicalDataLoaded && !!transactionsList.length && <Button
            variant="outlined"
            onClick={handleShowModal(true)}
            color='error'
            sx={[errorButtonSx, errorButtonMenuSx]}
          >
            + <FormattedMessage id="addHistory" />
          </Button>
          }
        </Box>
      </Box>
      {!historicalDataLoaded &&
        <Stack sx={emptyStackSx}>
          <img
            src={emptyConsumptionPlaceholder}
            alt="empty"
          />
          <Typography>
            <FormattedMessage id="emptyConsumption" />
          </Typography>
          <Button
            variant="outlined"
            onClick={handleShowModal(true)}
            color='error'
            sx={errorButtonSx}
          >
            + <FormattedMessage id="addHistory" />
          </Button>
        </Stack>
      }
      {(!!transactionsList.length || historicalDataLoaded) &&
        <ResponsiveContainer
          width="100%"
          aspect={responsive ? 0.8 : 3}
        >
          <AreaChart
            width={500}
            height={300}
            data={transactionsList}
            margin={{
              top: getNumberFromPx(5),
              right: 0,
              left: 0,
              bottom: getNumberFromPx(5),
            }}
          >
            <defs>
              <linearGradient id="colorValue"
                x1="0"
                y1="0"
                x2="0"
                y2="1">
                <stop offset="0%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={1} />
                <stop offset="100%"
                  stopColor={theme.palette.common.white}
                  stopOpacity={0} />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient id="colorOverLimit"
                x1="0"
                y1="0"
                x2="0"
                y2="1">
                <stop offset="0%"
                  stopColor={theme.palette.error.dark}
                  stopOpacity={1} />
                <stop offset="100%"
                  stopColor="#FFFFFF"
                  stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              height={responsive
                ? getNumberFromPx(80)
                : getNumberFromPx(50)
              }
              fontSize={getNumberFromPx(12)}
              dataKey="date"
              angle={responsive ? 90 : 0}
              tick={{ textAnchor: 'start', 'dominantBaseline': 'ideographic' }}
            />
            <YAxis fontSize={getNumberFromPx(12)} />
            <Legend />
            <Tooltip content={({ active, payload }) => {
              if (!active || !payload?.length) {
                return null
              }
              return (
                <Box sx={tooltipWrapperSx}>
                  <Typography variant="body1">
                    {payload[0].payload["overLimit"] ? payload[0].payload["overLimit"] : payload[0].payload["limit"]} kWh
                  </Typography>
                  <Typography variant="caption">
                    {payload[0].payload.name}
                  </Typography>
                </Box>
              )
            }} />
            <Area
              dataKey={"overLimit"}
              name={intl.formatMessage({ id: 'overLimit' })}
              fill="url(#colorOverLimit)"
              strokeWidth={2}
              stroke={theme.palette.error.dark}
            />
            <Area
              onAnimationEnd={handleAnimationEnd}
              dataKey={"limit"}
              name={intl.formatMessage({ id: 'limit' })}
              fill="url(#colorValue)"
              strokeWidth={2}
              stroke={theme.palette.primary.main}
            />
          </AreaChart>
        </ResponsiveContainer>
      }
      <AMPModal
        handleClose={handleShowModal(false)}
        open={modalOpen}
        title={intl.formatMessage({ id: "addingConsumptionHistory" })}
        type="md"
        fullWidth
        withoutPaddings
      >
        <UploadConsumptionHistoryForm
          userId={userId}
          handleClose={handleShowModal(false)}
          getTransactions={getData}
        />
      </AMPModal>
    </Box>
  )
}
