import { FC } from "react";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { notificationTypeTranslation, notificationTypeTranslationMessage } from "../../../utils/consts";
import { FormattedMessage, useIntl } from "react-intl";
import {
  avatarBoxSx,
  avatarGapSx,
  avatarSx,
  cardTextSx,
  containerSx,
  gridContainerSx,
  headerSx,
  infoBlockSx,
  titleTextSx,
  unreadLineSx,
  dateSx,
  readBoxSx,
  unreadSx,
  visibilitySx,
  dateBoxSmSx,
  dateBoxLgSx
} from "./NotificationInfoSection.sx";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { AppRouteEnum, AppRouteSpace } from "../../../utils/helper";
import { NotificationTypeEnum } from "../../../types";
import { NotificationInfoSectionProps } from "./NotificationInfoSection.types";
import { Visibility } from "@mui/icons-material";
import { handleMarkAsUnread } from "../NotificationCard";
import { notificationApi } from "../../../api";
import { useAppSelector } from "../../../app/hooks";
import { roleTextSx } from "../NotificationCard/NotificationCard.sx";

export const NotificationInfoSection:FC<NotificationInfoSectionProps> = ({data, location}) => {
  const intl = useIntl()
  const user = data?.user
  const [setAsUnread] = notificationApi.useMarkNotificationAsUnreadMutation()
  const { authInfo } = useAppSelector((state) => state.common)
  const navigate = useNavigate()
  const userName = user ? `${user?.firstName} ${user?.lastName}` : (!user && data?.location) ? data?.location?.name : ''
  
  if (!data) {
    return null
  }
  
  const handleUnreadClick = () => {
    handleMarkAsUnread(
      setAsUnread,
      Number(data.id),
      authInfo.business.id,
      data.type
    )
    navigate(AppRouteSpace([AppRouteEnum.app, AppRouteEnum.notifications]))
  }
  
  return (
    <Box sx={containerSx}>
      <Box sx={headerSx}>
        <Box sx={avatarBoxSx}>
          <Box sx={avatarGapSx}>
            <Avatar
              src={user?.avatar?.url}
              sx={avatarSx}
            />
          </Box>
          <Box>
            <Typography
              variant="h4"
            >
              {userName}
            </Typography>
            <Box sx={dateBoxSmSx}>
              <Typography
                sx={dateSx}
                component="span"
              >
                {dayjs(data.created).format('DD.MM.YYYY hh:mm')}
              </Typography>
            </Box>
            <Typography
              sx={roleTextSx}
              component="span"
            >
              {notificationTypeTranslation(intl)[data.type]}
            </Typography>
          </Box>
        </Box>
        <Box sx={dateBoxLgSx}>
          <Typography
            sx={dateSx}
            component="span"
          >
            {dayjs(data.created).format('DD.MM.YYYY hh:mm')}
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        spacing={2}
        sx={gridContainerSx}
      >
        {user && (
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="h4"
            >
              <FormattedMessage id="contractInfo"/>
            </Typography>
            <Box sx={infoBlockSx}>
              <Typography sx={titleTextSx}>
                <FormattedMessage id="evDriverName" />
              </Typography>
              <Link to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.users, String(user?.id)])}>
                <Typography
                  sx={cardTextSx}
                  color="primary"
                >
                  {`${user?.firstName} ${user?.lastName}`}
                </Typography>
              </Link>
            </Box>
            {user?.phoneNumber && (
              <Box sx={infoBlockSx}>
                <Typography sx={titleTextSx}>
                  <FormattedMessage id="phone" />
                </Typography>
                <Typography sx={cardTextSx}>
                  {user?.phoneNumber}
                </Typography>
              </Box>
            )}
            <Box sx={infoBlockSx}>
              <Typography sx={titleTextSx}>
                <FormattedMessage id="email" />
              </Typography>
              <Typography sx={cardTextSx}>
                {user?.emailAddress}
              </Typography>
            </Box>
            {user?.contract && (
              <Box sx={infoBlockSx}>
                <Typography sx={titleTextSx}>
                  <FormattedMessage id="contractId" />
                </Typography>
                <Link to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.contracts, String(user?.contract?.id)])}>
                  <Typography
                    sx={cardTextSx}
                    color="primary"
                  >
                    {user?.contract?.name}
                  </Typography>
                </Link>
              </Box>
            )}
          </Grid>
        )}
        {(data.type === NotificationTypeEnum.NEW_LOCATION) && (
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="h4"
            >
              <FormattedMessage id="location"/>
            </Typography>
            <Box sx={infoBlockSx}>
              <Typography sx={titleTextSx}>
                <FormattedMessage id="id" />
              </Typography>
              <Link to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.locations, String(data?.location?.id)])}>
                <Typography
                  sx={cardTextSx}
                  color="primary"
                >
                  {data?.location?.id}
                </Typography>
              </Link>
            </Box>
            <Box sx={infoBlockSx}>
              <Typography sx={titleTextSx}>
                <FormattedMessage id="address" />
              </Typography>
              <Typography
                sx={cardTextSx}
              >
                {`${data?.location?.address?.country}, ${data?.location?.address?.city}, ${data?.location?.address?.street}, ${data?.location?.address?.houseNumber}`}
              </Typography>
            </Box>
            <Box sx={infoBlockSx}>
              <Typography sx={titleTextSx}>
                <FormattedMessage id="zipCode" />
              </Typography>
              <Typography
                sx={cardTextSx}
              >
                {data?.location?.address?.zipCode}
              </Typography>
            </Box>
            <Box sx={infoBlockSx}>
              <Typography sx={titleTextSx}>
                <FormattedMessage id="parkingLots" />
              </Typography>
              <Typography
                sx={cardTextSx}
              >
                {data?.location?.parkingLots}
              </Typography>
            </Box>
            <Box sx={infoBlockSx}>
              <Typography sx={titleTextSx}>
                <FormattedMessage id="availableAmperes" />
              </Typography>
              <Typography
                sx={cardTextSx}
              >
                {data?.location?.availableAmperes}
              </Typography>
            </Box>
          </Grid>
        )}
        {(data.type === NotificationTypeEnum.NEW_CONTRACT_EXIST_LOCATION ||
          data.type === NotificationTypeEnum.NEW_CONTRACT_NEW_LOCATION ||
          data.type === NotificationTypeEnum.STATION_ASSOCIATION ||
          data.type === NotificationTypeEnum.NEW_USER_REGISTRATION ||
          data.type === NotificationTypeEnum.NEW_RFID_REQUEST) &&
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              variant="h4"
            >
              <FormattedMessage id="parkingAddress"/>
            </Typography>
            {(data.type === NotificationTypeEnum.NEW_CONTRACT_EXIST_LOCATION) &&
              <Box sx={infoBlockSx}>
                <Typography sx={titleTextSx}>
                  <FormattedMessage id="location" />
                </Typography>
                <Link to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.locations, String(user?.location?.id)])}>
                  <Typography
                    sx={cardTextSx}
                    color="primary"
                  >
                    {user?.location?.name}
                  </Typography>
                </Link>
              </Box>
            }
            <Box sx={infoBlockSx}>
              <Typography sx={titleTextSx}>
                <FormattedMessage id="address" />
              </Typography>
              <Typography sx={cardTextSx}>
                {`${user?.parkingAddress?.country}, ${user?.parkingAddress?.city}, ${user?.parkingAddress?.street}, ${user?.parkingAddress?.houseNumber}`}
              </Typography>
            </Box>
            <Box sx={infoBlockSx}>
              <Typography sx={titleTextSx}>
                <FormattedMessage id="zipCode" />
              </Typography>
              <Typography sx={cardTextSx}>
                {user?.parkingAddress?.zipCode}
              </Typography>
            </Box>
            <Box sx={infoBlockSx}>
              <Typography sx={titleTextSx}>
                <FormattedMessage id="parkingLot" />
              </Typography>
              <Typography sx={cardTextSx}>
                {user?.parkingAddress?.parkingPlace}
              </Typography>
            </Box>
            {
              data.type === NotificationTypeEnum.NEW_CONTRACT_EXIST_LOCATION &&   <Box sx={infoBlockSx}>
                <Typography sx={titleTextSx}>
                  <FormattedMessage id="availableAmperes" />
                </Typography>
                <Typography sx={cardTextSx}>
                  {location?.availableAmperes}
                </Typography>
              </Box>
            }
          </Grid>
        }
      </Grid>
      {
        data?.message && (
          <Grid
            container
            spacing={2}
            sx={gridContainerSx}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Box sx={infoBlockSx}>
                <Typography
                  variant="h4"
                  mb={3}
                >
                  <FormattedMessage id="message"/>
                </Typography>
                {notificationTypeTranslationMessage(intl, data)[data.type]}
                {/*{splitMessage()?.map(item => (*/}
                {/*  <Typography*/}
                {/*    sx={cardTextSx}*/}
                {/*    key={item}*/}
                {/*  >*/}
                {/*    {item}*/}
                {/*  </Typography>*/}
                {/*))}*/}
              </Box>
            </Grid>
          </Grid>
        )
      }
      <Grid
        container
        spacing={2}
        sx={gridContainerSx}
      >
        <Grid
          item
          xs={12}
        >
          <Box sx={unreadLineSx}>
            <Box sx={readBoxSx}>
              <Visibility sx={visibilitySx}/>
              <Typography
                sx={dateSx}
              >
                <FormattedMessage
                  id="readByOn"
                  values={{
                    name: `${data?.readByManager?.firstName} ${data?.readByManager?.lastName}`,
                    date: dayjs(data?.readAt).format('DD.MM.YYYY hh:mm')
                  }}
                />
              </Typography>
            </Box>
            <Button
              sx={unreadSx}
              onClick={handleUnreadClick}
            >
              <FormattedMessage id="markAsUnread" />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
