import { Box, Button, FormControl, FormLabel, TextField, Typography } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CancelButton } from "src/components/common/CancelButton";
import { feedbackInitialValues } from "../formData/common/initialData";
import { feedbackSchema, FeedbackType } from "../formData/feedback";
import { getHelperText } from "../../../utils/forms";
import { FeedbackFormType } from "./FeedbackForm.types";
import { formSx, formLabelSx, actionsWrapperSx, submitButtonSx } from "./FeedbackForm.sx";
import { AMPPhoneNumber } from "../../common/AMPPhoneNumber";
import { TariffType } from "../formData/tariff";
import { usersApi } from "../../../api";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { isErrorObject } from "../../../utils/helper";
import { NotifyError, NotifySuccess } from "../../../utils/notification";
import { AppDispatch } from "../../../app/store";
import { FeedbackTypeEnum } from "../../../types";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

type PostFeedbackMutationType = ReturnType<typeof usersApi.usePostFeedbackMutation>[0];

export const handlePostFeedback =  (
  trigger: PostFeedbackMutationType,
  dispatch: AppDispatch,
  userId: number,
  email: string,
  handleClose: () => void,
  type: FeedbackTypeEnum,
  messageSent: string
) => async (data: FeedbackType) => {
  try {
    dispatch(preloaderChangeStatus(true));
    const result = await trigger({
      userId,
      data: {
        email,
        feedbackType: type,
        message: data.message,
        phone: data.phone
      }
    }).unwrap().then(() => {
      NotifySuccess(messageSent)
    })
    
    return result
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message ?? error.data?.error)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
    handleClose()
  }
}

export const FeedbackForm: FC<FeedbackFormType> = ({ handleClose, type }) => {
  const intl = useIntl();
  const [postFeedback] = usersApi.usePostFeedbackMutation()
  const dispatch = useAppDispatch();
  const { authInfo } = useAppSelector((state) => state.common);
  
  const handleChangePhone = (setFieldValue: FormikProps<TariffType>['setFieldValue'], fieldName: string) =>
    (newValue: string | number | null | undefined) => {
      if(newValue){
        setFieldValue(fieldName, `+${newValue}`)
      }
    }
    
  return (
    <Formik
      initialValues={feedbackInitialValues}
      onSubmit={
        handlePostFeedback(
          postFeedback,
          dispatch,
          authInfo.id,
          authInfo.emailAddress,
          handleClose,
          type,
          intl.formatMessage({id: 'messageSent'})
        )}
      validationSchema={feedbackSchema}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values
      }: FormikProps<FeedbackType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box
            sx={formSx}
          >
            <AMPPhoneNumber
              value={values["phone"]}
              onChange={handleChangePhone(setFieldValue, "phone")}
              label={"phone"}
              onBlur={handleBlur}
              hasError={
                touched["phone"] && Boolean(errors["phone"])
              }
              helperText={getHelperText("phone", touched, errors, intl)}
            />
            <FormControl>
              <FormLabel sx={formLabelSx}>
                <Typography variant="caption">
                  {intl.formatMessage({ id: 'message' })}
                </Typography>
              </FormLabel>
              <TextField
                id="message"
                multiline
                placeholder={intl.formatMessage({id:'messageGoes'})}
                rows={4}
                value={values['message']}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                helperText={getHelperText('message', touched, errors, intl)}
              />
            </FormControl>
            <Box sx={actionsWrapperSx}>
              <CancelButton
                text="cancel"
                handler={handleClose}
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                sx={submitButtonSx}
              >
                <Typography
                  variant="button"
                  color="textSecondary"
                >
                  <FormattedMessage id="send" />
                </Typography>
              </Button>
            </Box>
          </Box>
        </Form>
      )
      }
    </Formik>
  );
}
