import { Box, Grid, Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { usersApi } from "src/api";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { store } from "src/app/store";
import { PageHeader } from "src/components/common/PageHeader";
import { PowerConsumption } from "src/components/common/PowerConsumption";
import { UserActions } from "src/components/users/UserActions";
import { UserDetails } from "src/components/users/UserDetails";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { UserByIdRequest } from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { RfidTable } from "../../components/common/RfidTable";
import { gridElementSx, historyNotification } from "./UserInfo.sx";
import { AttentionIcon } from "../../components/icons/AttentionIcon";
import { Can } from "src/permissions/Can";

type UserQueryTrigger = ReturnType<typeof usersApi.useLazyGetUserByIdQuery>[0];

const handleGetUserInfo = (trigger: UserQueryTrigger, setIsHistoricalDataShown: React.Dispatch<React.SetStateAction<boolean>>) => (async (data: UserByIdRequest) => {
  store.dispatch(preloaderChangeStatus(true))
  try {
    const response = await trigger(data).unwrap()
    store.dispatch(preloaderChangeStatus(false))
    setIsHistoricalDataShown(response.historicalDataLoaded)
    return response
  } catch (error) {
    if (isErrorObject(error)) {
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
});

export const UserInfo: FC = () => {
  const params = useParams();
  const { authInfo, activeBusiness } = useAppSelector((state) => state.common);

  const dispatch = useAppDispatch()
  const [trigger, response] = usersApi.useLazyGetUserByIdQuery()

  const [isHistoricalDataShown, setIsHistoricalDataShown] = useState<boolean>(true) 

  useEffect(() => {
    (Object.keys(authInfo).length && params.id) && handleGetUserInfo(trigger, setIsHistoricalDataShown)({ userId: +params.id, businessId: activeBusiness })
  }, [])

  return (
    <Box>
      <PageHeader
        title={`${response.data?.firstName} ${response.data?.lastName}` ?? ''}
        onlyTitle
      />
      {!isHistoricalDataShown &&
        <Stack sx={historyNotification}>
          <Box>
            <AttentionIcon />
          </Box>
          <Typography variant="caption">
            <FormattedMessage id="needHistory" />
          </Typography>
        </Stack>
      }
      {response.data && <Grid
        container
        rowSpacing={{ xs: 2 }}
        columnSpacing={{ xs: 0, md: 2 }}
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={gridElementSx}
        >
          <UserDetails
            data={response.data}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={gridElementSx}
        >
          <UserActions
            data={response.data}
          />
        </Grid>
        <Can
          I="read"
          a="chargers"
        >
          <Grid
            item
            xs={12}
          >
            {
              response.data?.chargePoint && <PowerConsumption
                dateFrom={response.data.contract.created}
                userId={response.data.id}
                businessId={response.data.business.id}
                chargerId={response.data.chargePoint.id}
                locationId={response.data?.location?.id}
                annualWh={response.data?.contract?.tariff?.annualWhLimit ?? 0}
                historicalDataLoaded={response?.data?.historicalDataLoaded}
              />
            }
          </Grid>
        </Can>
      </Grid>}
      <Can
        I="read"
        a="rfId"
      >
        <Box sx={{ mt: 3 }}>
          <RfidTable data={response.data} />
        </Box>
      </Can>
    </Box>
  );
};
