import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SignatureCanvas from 'react-signature-canvas'
import { NotifyWarning } from "src/utils/notification";
import { CancelButton } from "../CancelButton";
import { SubmitButton } from "../SubmitButton";
import { actionsActiveButtonsSx, actionsWrapperSx, canvasWrapperSx, headerSx, rootSx, titleSx } from "./Signature.sx";
import { SignatureTypes } from "./Signature.types";
import { Link } from "react-router-dom";
import { AppRouteEnum, AppRouteSpace } from "../../../utils/helper";
import theme from "src/theme";
import { TermsText } from "../TermsText";
import ability from "../../../permissions/ability";

export const Signature: FC<SignatureTypes> = ({ modal = false, handleSubmit, handleClose, signature = '', canvasHeight }) => {
  const canvas = useRef<SignatureCanvas>(null)
  const intl = useIntl()
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [agreeWithTerms, setAgreeWithTerms] = useState(false)
  const canReadTermsOfContract = ability.can('read', 'termsOfContract')
  
  const handleAgreeTerms = (event: ChangeEvent<HTMLInputElement>) => {
    setAgreeWithTerms(event.target.checked)
  }
  
  const handleSubmitSigning = () => {
    if(!canvas.current){
      return
    }
    if(canvas.current.isEmpty()){
      NotifyWarning(intl.formatMessage({id: 'fillSignatureField'}))
      return
    }
    setDisableButton(true)
    fetch(canvas.current.toDataURL('png'))
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){
        handleSubmit(new File([buf], 'signature.txt'));
      })
    setTimeout(() => {
      setDisableButton(false)
    }, 2000)
  }
  
  useEffect(() => {
    signature && canvas.current?.fromDataURL(signature)
  }, [signature])
  
  const handleClear = () => {
    if(!canvas.current){
      return
    }
    
    canvas.current.clear()
  }
  return (
    <Box sx={rootSx(modal)}>
      <Grid
        container
        spacing={2}
        sx={titleSx(modal)}
      >
        {!modal && <Grid
          item
          xs={12}
          sx={headerSx}
        >
          <Typography variant="subtitle1">
            <FormattedMessage id="putSignature" />
          </Typography>
          <Button
            onClick={handleClear}
          >
            <Typography
              variant="button"
              color="primary.main"
            >
              <FormattedMessage id="clear" />
            </Typography>
          </Button>
        </Grid>}
        {canReadTermsOfContract &&
          <Grid
            item
            xs={12}
            sx={{
              ml: modal ? 2 : 0,
              mt: modal ? 2 : 0
            }}
          >
            <FormControlLabel
              label={<TermsText />}
              control={
                <Checkbox
                  color="secondary"
                  name="agreements"
                  value={agreeWithTerms}
                  onChange={handleAgreeTerms}
                  required
                />
              }
            />
          </Grid>
        }
        <Grid
          item
          xs={12}
          sx={canvasWrapperSx(modal, canvasHeight)}
        >
          <SignatureCanvas
            ref={canvas}
            canvasProps={{className: 'sigCanvas'}}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          {!modal &&
            <SubmitButton
              text="sign"
              color="brand"
              fullWidth
              handler={handleSubmitSigning}
              isSubmitting={disableButton || (!agreeWithTerms && canReadTermsOfContract)}
            />
          }
          {modal && <Box sx={actionsWrapperSx}>
            { handleClose && <CancelButton
              text="cancel"
              handler={handleClose}
            />}
            <Box sx={actionsActiveButtonsSx}>
              <Button
                onClick={handleClear}
              >
                <Typography
                  variant="button"
                  color="primary.main"
                >
                  <FormattedMessage id="clear" />
                </Typography>
              </Button>
              <SubmitButton
                text="submit"
                color="primary"
                handler={handleSubmitSigning}
                isSubmitting={disableButton || (!agreeWithTerms && canReadTermsOfContract)}
              />
            </Box>
          </Box>
          }
        </Grid>
      </Grid>
    </Box>
  )
};
