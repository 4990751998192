import { Navigate } from 'react-router-dom';
import { PageLayout } from './layouts/PageLayout';
import { Login } from './pages/Login';
import { SignUp } from './pages/SignUp';
import { Locations } from './pages/Locations';
import { Welcome } from './pages/Welcome';
import { DashboardLayout } from './layouts/DashboardLayout';
import { LocationInfo } from './pages/LocationInfo';
import { Users } from './pages/Users';
import { UserInfo } from './pages/UserInfo';
import { Chargers } from './pages/Chargers';
import { Tariffs } from './pages/Tariffs';
import { ForgotPassword } from './pages/ForgotPassword';
import { ResetPassword } from './pages/ResetPassword';
import { Contracts } from './pages/Contracts';
import { BusinessInfo } from './pages/BusinessInfo';
import { ChargerInfo } from './pages/ChargerInfo';
import { ContractInfo } from './pages/ContractInfo';
import { TariffInfo } from './pages/TariffInfo';
import { Reports } from './pages/Reports';
import { ConfirmRegistartion } from './pages/ConfirmRegistartion';
import { ContractStatusEnum, RoleEnum, StatusEnum } from './enums';
import { ErrorPage } from './pages/404';
import { store } from './app/store';
import { VerifyEmail } from './pages/VerifyEmail';
import { Invoices } from './pages/Invoices';
import { ChargingSessions } from "./pages/ChargingSessions";
import { ActiveChargingSessions } from "./pages/ActiveChargingSessions";
import { ChargerConfigurations } from './pages/ChargerConfigurations';
import { EnergyConsumptionReport } from './pages/EnergyConsumptionReport';
import { UsersReport } from './pages/UsersReport';
import { RevenueReport } from './pages/RevenueReport';
import { Complete } from './pages/Complete';
import { TermsOfUse } from './pages/TermsOfUse';
import { TermsOfUseFr } from './pages/TermsOfUseFr';
import { TermsOfUseDe } from './pages/TermsOfUseDe';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { PrivacyPolicyFrench } from './pages/PrivacyPolicyFrench';
import { Support } from './pages/Support';
import { SupportFr } from './pages/SupportFr';
import { Notifications } from "./pages/Notifications";
import { TechnicalTeam } from './pages/TechnicalTeam';
import { TechnicalTeamInfo } from './pages/TechnicalTeamInfo';
import { NotificationInfo } from './pages/NotificationInfo';
import { PrivacyPolicyDeutsch } from './pages/PrivacyPolicyDeutsch';
import { SupportDe } from './pages/SupportDe';

const routes = () => {
  const isDriver = Object.keys(store.getState().common.authInfo).length && store.getState().common.authInfo?.roles[0].role == RoleEnum.DRIVER
  const isStatusOperation = Object.keys(store.getState().common.authInfo).length && !isDriver && store.getState().common.authInfo?.contract?.status === ContractStatusEnum.OPERATION
  const isChargePointAvailable = Object.keys(store.getState().common.authInfo).length && !isDriver && store.getState().common.authInfo.chargePoint
  return [
    {
      path: 'app',
      element: localStorage.getItem('auth') ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        {
          index: true,
          element: isDriver && isStatusOperation ? isChargePointAvailable ? <Navigate to={`/app/chargers/${store.getState().common.authInfo?.chargePoint.id}`} /> : <Navigate to={`/app/contracts/${store.getState().common.authInfo?.contract.id}`} /> : <Navigate to="/app/welcome" />
        },
        {
          path: 'welcome',
          element: isDriver ? <Welcome /> : <Navigate to="/app/locations" />,
        },
        {
          path: 'locations',
          element: <Locations />
        },
        {
          path: 'locations/:id',
          element: <LocationInfo />
        },
        {
          path: 'users',
          element: <Users />
        },
        {
          path: 'users/:id',
          element: <UserInfo />
        },
        {
          path: 'chargers',
          element: <Chargers />
        },
        {
          path: 'chargers/:id',
          element: <ChargerInfo />
        },
        {
          path: 'chargers/:id/configurations',
          element: <ChargerConfigurations />
        },
        {
          path: 'tariffs',
          element: <Tariffs />
        },
        {
          path: 'tariffs/:id',
          element: <TariffInfo />
        },
        {
          path: 'contracts',
          element: <Contracts />
        },
        {
          path: 'contracts/:id',
          element: <ContractInfo />
        },
        {
          path: 'users/business/:id',
          element: <BusinessInfo />
        },
        {
          path: 'reports',
          element: <Reports />
        },
        {
          path: 'reports/charging-sessions',
          element: <ChargingSessions />
        },
        {
          path: 'reports/active-charging-sessions',
          element: <ActiveChargingSessions />
        },
        {
          path: 'reports/energy-consumption',
          element: <EnergyConsumptionReport />
        },
        {
          path: 'reports/users',
          element: <UsersReport />
        },
        {
          path: 'reports/revenue',
          element: <RevenueReport />
        },
        {
          path: 'invoices',
          element: <Invoices />
        },
        {
          path: 'technical-team',
          element: <TechnicalTeam />
        },
        {
          path: 'technical-team/:id',
          element: <TechnicalTeamInfo />
        },
        {
          path: 'notifications',
          element: <Notifications />
        },
        {
          path: 'notifications/:id',
          element: <NotificationInfo />
        }
      ]
    },
    {
      path: '/',
      element: <PageLayout />,
      children: [
        {
          index: true,
          element: !localStorage.getItem('auth') && <Navigate to="/login" />
        },
        {
          path: 'login',
          element: <Login />
        },
        {
          path: 'signUp',
          element: <SignUp />
        },
        {
          path: 'forgot',
          element: <ForgotPassword />
        },
        {
          path: 'reset-password',
          element: <ResetPassword />
        },
        {
          path: 'completeRegistration',
          element: <Complete />
        },
        {
          path: 'confirmRegistration',
          element: <ConfirmRegistartion />
        },
        {
          path: 'verifyEmail',
          element: <VerifyEmail />
        },
        {
          path: 'privacy-policy',
          element: <Navigate to={`/privacy-policy/${store.getState().common.lang}`} />
        },
        {
          path: 'privacy-policy/fr',
          element: <PrivacyPolicyFrench />
        },
        {
          path: 'privacy-policy/de',
          element: <PrivacyPolicyDeutsch />
        },
        {
          path: 'terms-of-use',
          element: <TermsOfUse />
        },
        {
          path: 'terms-of-use/en',
          element: <TermsOfUse />
        },
        {
          path: 'terms-of-use/fr',
          element: <TermsOfUseFr />
        },
        {
          path: 'terms-of-use/de',
          element: <TermsOfUseDe />
        },
        {
          path: 'privacy-policy/en',
          element: <PrivacyPolicy />,
        },
        {
          path: 'support',
          element: <Navigate to={`/support/${store.getState().common.lang}`} />,
        },
        {
          path: 'support/en',
          element: <Support />,
        },
        {
          path: 'support/fr',
          element: <SupportFr />,
        },
        
        {
          path: 'support/de',
          element: <SupportDe />,
        },
      ]
    },
    {
      path: '*',
      element: <ErrorPage />
    }
  ];
};

export default routes;
