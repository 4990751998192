import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'src/axiosBaseQuery'
import { updateContractStatus } from 'src/stores/common/commonSlice'
import {
  ContractByIdRequest,
  ContractDTO,
  ContractPdfDataRequest,
  ContractSearchRequest,
  CreateContractRequest,
  CreateManualContractRequest,
  PageContractDTO,
  SignContractRequest, ToggleContractStatusRequest, UploadContractRequest
} from 'src/types'
import { getBaseURL } from 'src/utils/helper'

export const contractsApi = createApi({
  reducerPath: 'contractsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: String(getBaseURL() + '/contracts'),
  }),
  tagTypes: ['Contract'],
  endpoints: (build) => ({
    getContracts: build.query<PageContractDTO, ContractSearchRequest>({
      query: ({ data, params }) => ({ url: '/search', method: 'post', data, params }),
      providesTags: (result) => {
        if (result?.content) {
          return [
            { type: 'Contract' as const, id: 'LIST' },
            ...result.content.map(contract => ({
              type: 'Contract' as const,
              id: contract?.id
            }))
          ]
        }
        return [
          { type: 'Contract' as const, id: 'LIST' }, 'Contract' as const
        ]
      }
    }),
    getContractById: build.query<ContractDTO, ContractByIdRequest>({
      query: (params) => ({ url: `/${params.contractId}`, method: 'get', params}),
      providesTags: (result, error, arg) => [{ type: 'Contract' as const, id: arg.contractId}]
    }),
    createContract: build.mutation<ContractDTO, CreateContractRequest>({
      query: (data) => ({ url: '', method: 'post', data }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(updateContractStatus(data))
        } catch (err) {
          console.error(err);
        }
      }
    }),
    createManualContract: build.mutation<ContractDTO, CreateManualContractRequest>({
      query: (data) => ({
        url: '/manual',
        method: 'post',
        data
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Contract' as const, id: arg.contractId },
        { type: 'Contract' as const, id: 'LIST' }
      ]
    }),
    uploadContract: build.mutation<ContractDTO, UploadContractRequest>({
      query: ({ businessId, file, contractId }) => ({
        url: `/${contractId}/manual/file`,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        method: 'post',
        params: {businessId},
        data: file,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Contract' as const, id: arg.contractId }
      ]
    }),
    contractPdf: build.query<string, ContractPdfDataRequest>({
      query: ({businessId, contractId, userId}) => ({
        url: `/${contractId}/pdf`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        responseType: 'blob',
        params: {businessId, userId}
      }),
      providesTags: (result, error, arg) => [{ type: 'Contract' as const, id: arg.contractId}],
      transformResponse(baseQueryData) {
        if(baseQueryData instanceof Blob){
          return URL.createObjectURL(baseQueryData)
        }
        return ''
      }
    }),
    signContract: build.mutation<ContractDTO, SignContractRequest>({
      query: ({ formData, contractId }) => ({
        url: `/${contractId}/signature`,
        headers: { 
          'Content-Type': 'multipart/form-data'
        },
        method: 'post',
        data: formData,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Contract' as const, id: arg.contractId }
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(updateContractStatus(data))
        } catch (err) {
          console.error(err);
        }
      }
    }),
    toggleContractStatus: build.mutation<ContractDTO, ToggleContractStatusRequest>({
      query: ({formData, contractId}) => ({
        url: `/${contractId}/operation`,
        method: 'post',
        data: formData,
      }),
      invalidatesTags: ['Contract']
    })
  }),
})
