import { GridColumns } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import { ColoredStatus } from 'src/components/common/ColoredStatus';
import { ColorField } from 'src/components/common/ColorField';
import { TableIdCell } from 'src/components/common/TableIdCell';
import { ConnectorStatusEnum, RoleEnum, UsersSortFieldEnum } from 'src/enums';
import { AppRouteEnum, AppRouteSpace } from 'src/utils/helper';
import { DeleteUser } from '../DeleteUser';
import { HistoryCell } from '../HistoryCell';
import { UserCell } from '../UserCell';
import dayjs from "dayjs";

export const columnConfigUsers = (role?: RoleEnum): GridColumns => ([
  {
    field: 'name',
    renderHeader: (params) => <FormattedMessage id={"nameColumnHeader"} />,
    flex: 2,
    minWidth: 200,
    sortable: false,
    cellClassName: 'ampTableCell',
    renderCell: (params) => (
      <UserCell data={params.row} />
    )
  },
  {
    field: UsersSortFieldEnum.ROLE,
    renderHeader: (params) => <FormattedMessage id={"userRoleColumnHeader"} />,
    flex: 1,
    minWidth: 120,
    cellClassName: 'ampTableCell',
    renderCell: (params) => params.row.roles.length ? <ColorField label={params.row.roles[0].role} /> : ''
  },
  
  {
    field: 'contractStatus',
    renderHeader: (params) => <FormattedMessage id={"contractStatusColumnHeader"} />,
    flex: 2,
    minWidth: 110,
    sortable: false,
    cellClassName: 'ampTableCell',
    renderCell: (params) =>{
      return (
        params.row.contract && <ColorField label={params.row.contract.status} />
      )
    } 
  },
  {
    field: UsersSortFieldEnum.EMAIL,
    renderHeader: (params) => <FormattedMessage id={"emailColumnHeader"} />,
    flex: 4,
    minWidth: 300,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.emailAddress
  },
  {
    field: UsersSortFieldEnum.CREATED,
    renderHeader: (params) => <FormattedMessage id={"dateColumnHeader"} />,
    flex: 1,
    minWidth: 100,
    cellClassName: 'ampTableCell',
    valueGetter: (params) =>  dayjs(params.row.created).format("DD/MM/YY")
  },
  {
    field: 'address',
    renderHeader: (params) => <FormattedMessage id={"locationColumnHeader"} />,
    flex: 4,
    minWidth: 300,
    sortable: false,
    cellClassName: 'ampTableCell',
    renderCell: (params) => {
      if(params.row.location){
        return role === RoleEnum.CLIENT_MANAGER
          ? <TableIdCell
            businessId={params.row.business.id}
            label={params.row.location.name}
            link={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.locations, params.row.location.id])}
            withoutCopy
          />
          : params.row.location.name
      }
      return ''
    }
  },
  {
    field: 'history',
    renderHeader: (params) => <FormattedMessage id={"historyColumnHeader"} />,
    flex: 1,
    sortable: false,
    cellClassName: 'ampTableCell',
    renderCell: (params) => <HistoryCell value={params.row.historicalDataLoaded}/>
  },
  {
    field: 'delete',
    flex: 1,
    headerName: '',
    sortable: false,
    cellClassName: 'ampTableCell hoverableCell',
    renderCell: (params) => (
      params.row.business && <DeleteUser row={params.row}/>
    )
  }
])
