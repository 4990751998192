import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react"
import { Box, Grid, SelectChangeEvent, Typography } from "@mui/material"
import { Form, Formik, FormikHandlers, FormikHelpers, FormikProps } from "formik"
import { FormattedMessage, useIntl } from "react-intl"
import { chargersApi, locationsApi, usersApi } from "src/api"
import { chargerInitialValues } from "../formData/common/initialData"
import { AMPInput } from "src/components/common/AMPInput"
import { getHelperText } from "src/utils/forms"
import { SubmitButton } from "src/components/common/SubmitButton"
import { ChargerFormType } from "./ChargerForm.types"
import { AMPFormSelect } from "src/components/common/AMPFormSelect"
import { chargerSchema, ChargerType } from "../formData/charger"
import { CancelButton } from "src/components/common/CancelButton"
import { formSx, actionsWrapperSx } from "./ChargerForm.sx"
import { BaseLocationInfo, BaseUserInfo, ConnectorDescription, LocationSearchRequestData, ModelDTO } from "src/types"
import { ConnectorCard } from "src/components/connectors/ConnectorCard"
import { RoleEnum } from "src/enums"
import { AMPAutocomplete } from "src/components/common/AMPAutocomplete"
import { AMPAutocompleteProps } from "src/components/common/AMPAutocomplete/AMPAutocomplete.types"
import { TableFilterFormTypes } from "src/components/reports/TableFilter/TableFilter.types"
import { findAutocompleteValueById, handleScroll, valuesTrimmer } from "src/utils/helper"
import { NotifyError } from "src/utils/notification"

export const ChargerForm: FC<ChargerFormType> = ({ handleClose, handleSubmit, initialValue, authInfo }) => {
  const intl = useIntl();
  const pageSizeLocations = 10
  const pageSizeClients = 10
  const [activeLocation, setActiveLocation] = useState<string>('')
  const [activeConnectors, setActiveConnectors] = useState<ConnectorDescription[]>()
  const [pageLocations, setPageLocations] = useState(0)
  const [pageClients, setPageClients] = useState(0)
  const [locationsData, setLocationsData] = useState<Array<BaseLocationInfo>>([])
  const [clientsData, setClientsData] = useState<Array<BaseUserInfo>>([])
  const [getLocations, locations] = locationsApi.useLazyGetLocationsQuery()
  const [getClients, clients] = usersApi.useLazyGetUsersQuery()
  const [getModels, models] = chargersApi.useLazyGetModelsQuery()
  const [keyLocations, setKeyLocations] = useState('')
  const [keyClient, setKeyClient] = useState('')
  
  const getLocationsData = async (keyword?: string) => {
    const data: LocationSearchRequestData = {
      keyword,
    }
    if(authInfo.business){
      data['businessId'] = +authInfo.business.id
    }
    await getLocations({ data, params: {size: pageSizeLocations, page: pageLocations }})
  }
  
  const getUsersData = async (keyword?: string) => {
    activeLocation &&
    await getClients({
      data: {
        businessId: +authInfo.business.id,
        locationId: +activeLocation,
        roles: [RoleEnum.DRIVER],
        chargerExists: false,
        contractIsSigned: true,
        keyword
      },
      params: {
        size: pageSizeClients,
        page: pageClients
      }
    })
  }
  
  const getModelsData = async () => {
    if(authInfo.business){
      await getModels(+authInfo.business.id)
    }
  }
  
  const processedLocations = useCallback(() => {
    if(!locationsData){
      return []
    }
    return locationsData.map((location: BaseLocationInfo) => ({
      id: location.id,
      label: location.name
    }))
  }, [locationsData])
  
  const processedClients = useCallback(() => {
    if(!clientsData){
      return []
    }
    return clientsData.filter(client => client.id !== initialValue?.user?.id ).map((client) => ({
      id: client.id,
      label: `${client.firstName} ${client.lastName}`
    }))
  }, [clientsData])
  
  const processedModels = useCallback(() => {
    if(!models.data?.content.length){
      return []
    }
    return models.data.content.map((model: ModelDTO) => ({
      id: model.id,
      value: String(model.id),
      name: model.name
    }))
  }, [models])
  
  useEffect(() => {
    const content = locations?.data?.content ?? []
    const allData = [...locationsData, ...content].reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur
      }
    }, {})
    setLocationsData(Array.from(Object.values(allData)))
  }, [locations?.data?.content])
  
  useEffect(() => {
    const content = clients?.data?.content ?? []
    const allData = [...clientsData, ...content].reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur
      }
    }, {})
    setClientsData(Array.from(Object.values(allData)))
  }, [clients?.data?.content])

  useEffect(() => {
    getModelsData()
    getLocationsData()
  }, [])
  
  useEffect(() => {
    getLocationsData(keyLocations)
  }, [pageLocations])
  
  useEffect(() => {
    if(activeLocation){
      getUsersData(keyClient.length ? keyClient : undefined)
    }
  }, [activeLocation, pageClients])

  const handleChangeLocation = (setFieldValue: FormikProps<TableFilterFormTypes>['setFieldValue'], fieldName: string): AMPAutocompleteProps['onChange'] =>
    (e, newValue) => {
      setClientsData([])
      setActiveLocation(String(newValue?.id || ''));
      setFieldValue(fieldName, newValue?.id ?? null)
    };
  
  const handleChangeClient = (setFieldValue: FormikProps<TableFilterFormTypes>['setFieldValue'], fieldName: string): AMPAutocompleteProps['onChange'] =>
    (e, newValue) => {
      setFieldValue(fieldName, newValue?.id ?? null)
      setKeyClient('')
    };

  const handleChangeModel = (
    handleChange: FormikHandlers["handleChange"],
    event: SelectChangeEvent,
  ) => {
    models.data && setActiveConnectors(models.data.content.find(item => item.id === +event.target.value)?.connectorDescriptions)
    return handleChange(event);
  }

  const getInitialValues = useMemo(() => {
    if(!initialValue) {
      return chargerInitialValues
    }
    const locationId = initialValue.location ? initialValue.location.id : ''
    const clientId = initialValue.user ? initialValue.user.id : ''
    setActiveLocation(locationId)
    models.data && setActiveConnectors(models.data.content.find(item => item.id === initialValue.model.id)?.connectorDescriptions)
    return {
      locationId: locationId,
      userId: clientId,
      chargePointId: String(initialValue.chargePointId),
      modelId: String(initialValue.model.id)
    }
  }, [initialValue, chargerInitialValues, models])

  const onHandleSubmit = (value: ChargerType, formikHelpers: FormikHelpers<ChargerType>) => {
    const values = valuesTrimmer(value)
    if(clients?.data?.content){
      const currentUser = clients.data.content.find(client => client.id === +values['userId'])
      if(currentUser && !currentUser.contract){
        NotifyError(intl.formatMessage({id: 'noContractClient'}))
        formikHelpers.setSubmitting(false)
        return
      }
    }
    handleSubmit(values, formikHelpers)
  }
  
  const handleScrollLocations = () => {
    handleScroll(
      locations?.data?.number ?? 0,
      locations?.data?.totalPages ?? 0,
      pageLocations,
      setPageLocations
    )
  }
  
  const handleScrollClients = () => {
    handleScroll(
      clients?.data?.number ?? 0,
      clients?.data?.totalPages ?? 0,
      pageClients,
      setPageClients
    )
  }
  
  const handleInputLocations = () =>
    (event: SyntheticEvent, value: string) => {
      console.log('dsfsdf')
      setPageLocations(0)
      setPageClients(0)
      getLocationsData(value)
      setKeyLocations(value)
    }
  
  const handleInputClients = () =>
    (event: SyntheticEvent, value: string) => {
      setPageClients(0)
      getUsersData(value)
      setKeyClient(value)
    }
  
  return (
    <Formik
      initialValues={getInitialValues}
      validationSchema={chargerSchema}
      onSubmit={onHandleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }: FormikProps<ChargerType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box
            sx={formSx}
          >
            <Box>
              <Typography
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage id="optional" />
              </Typography>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{xs: 0, md: 2}}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPAutocomplete
                    handleScroll={handleScrollLocations}
                    onInputChange={handleInputLocations()}
                    options={processedLocations()}
                    value={findAutocompleteValueById(processedLocations(), +values['locationId'])}
                    label={'location'}
                    input={'locationId'}
                    onChange={handleChangeLocation(setFieldValue, 'locationId')}
                    onBlur={handleBlur}
                    hasError={touched['locationId'] && Boolean(errors['locationId'])}
                    helperText={getHelperText('locationId', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPAutocomplete
                    handleScroll={handleScrollClients}
                    onInputChange={handleInputClients()}
                    options={processedClients()}
                    value={findAutocompleteValueById(processedClients(), +values['userId'])}
                    label={'client'}
                    input={'userId'}
                    onChange={handleChangeClient(setFieldValue, 'userId')}
                    onBlur={handleBlur}
                    hasError={touched['userId'] && Boolean(errors['userId'])}
                    helperText={getHelperText('userId', touched, errors, intl)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                color="textPrimary"
              >
                <FormattedMessage id="chargerInfo" />
              </Typography>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{xs: 0, md: 2}}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPInput
                    value={values['chargePointId']}
                    type={'text'}
                    label={'chargePointId'}
                    input={'chargePointId'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched['chargePointId'] && Boolean(errors['chargePointId'])}
                    helperText={getHelperText('chargePointId', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  xs={12}
                  lg={6}
                  item
                >
                  <AMPFormSelect
                    data={processedModels()}
                    value={values['modelId']}
                    label={'stationModel'}
                    input={'modelId'}
                    onChange={(e: SelectChangeEvent) => handleChangeModel(handleChange, e)}
                    onBlur={handleBlur}
                    hasError={touched['modelId'] && Boolean(errors['modelId'])}
                    helperText={getHelperText('modelId', touched, errors, intl)}
                  />
                </Grid>
              </Grid>
            </Box>
            {activeConnectors?.length && 
              <Box>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                >
                  <FormattedMessage id="connectors" />
                </Typography>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{xs: 0, md: 2}}
                >
                  {activeConnectors.map((connector, index) => (
                    <Grid
                      key={connector.connectorId}
                      xs={12}
                      lg={6}
                      item
                    >
                      <ConnectorCard
                        connector={connector}
                        number={index + 1}
                      />
                    </Grid> 
                  ))}
                </Grid>
              </Box>
            }
          </Box>
          <Box sx={actionsWrapperSx}>
            <CancelButton
              text="cancel"
              handler={handleClose}
            />
            <SubmitButton
              text={"submit"}
              isSubmitting={isSubmitting}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
}
