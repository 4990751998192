import { FC, useState, MouseEvent } from "react";
import { Link } from "react-router-dom";
import { ContentCopyOutlined } from "@mui/icons-material";
import { Button, IconButton, Popover, Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import copy from "copy-to-clipboard";
import { NotifyError, NotifySuccess } from "../../../../utils/notification";
import { usersApi } from "../../../../api";
import { useAppDispatch } from "../../../../app/hooks";
import { preloaderChangeStatus } from "../../../../stores/common/commonSlice";
import { AppDispatch, BaseUserInfo } from "../../../../types";
import { AppRouteEnum, AppRouteSpace, isErrorObject } from "../../../../utils/helper";
import {
  iconBtnSx,
  popoverSx,
  menuBtnSx,
  menuBtnTextSx,
  titleTextSx,
  withIconSx,
  idTextSx
} from "./UserItemCard.sx";
import { ColorField } from "src/components/common/ColorField";
import { TableCard } from "../../../tables/respnsiveTable/TableCard";
import { MenuWrapper } from "../../../tables/respnsiveTable/MenuWrapper";
import { MenuButton } from "../../../tables/respnsiveTable/MenuButton";
import { TableRow } from "../../../tables/respnsiveTable/TableRow";
import dayjs from "dayjs";

type DeleteUserMutationTrigger = ReturnType<typeof usersApi.useDeleteUserMutation>[0]

const handleDeleteUser = (trigger: DeleteUserMutationTrigger, dispatch: AppDispatch, userId: number, businessId: number) => async () => {
  try{
    dispatch(preloaderChangeStatus(true))
    await trigger({ userId, businessId }).unwrap().finally(() => {
      dispatch(preloaderChangeStatus(false))
    })
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
}


export const UserItemCard:FC<BaseUserInfo> = ({
  id,
  roles,
  firstName,
  lastName,
  contract,
  emailAddress,
  business,
  location,
  created
}) => {
  const intl = useIntl();
  const [trigger] = usersApi.useDeleteUserMutation();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const copyToClipboard = () => {
    copy(String(id), { debug: true })
    NotifySuccess(intl.formatMessage({id: 'copiedSuccesfully'}))
  }
  
  return (
    <TableCard>
      {business &&
        <MenuWrapper>
          <MenuButton handleClick={handleClick}/>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={popoverSx}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Button
              sx={menuBtnSx}
              onClick={handleDeleteUser(trigger, dispatch, +id, +business.id)}
            >
              <Typography sx={menuBtnTextSx}>
                {intl.formatMessage({id: "delete"})}
              </Typography>
            </Button>
          </Popover>
        </MenuWrapper>}
      <Typography sx={titleTextSx}>
        {intl.formatMessage({id: 'userId'})}
      </Typography>
      <Stack sx={withIconSx}>
        <Link to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.users, String(id)])}>
          <Typography sx={idTextSx}>
            {id}
          </Typography>
        </Link>
        <IconButton
          onClick={copyToClipboard}
          sx={iconBtnSx}
        >
          <ContentCopyOutlined sx={{width: '0.75rem'}} />
        </IconButton>
      </Stack>
      <TableRow
        titleId='nameColumnHeader'
        value={`${firstName} ${lastName}`}
      />
      <TableRow
        titleId='userRoleColumnHeader'
        customValue={roles.length ? <ColorField label={roles[0].role} /> : '-'}
      />
      <TableRow
        titleId='contractStatusColumnHeader'
        customValue={contract ? <ColorField label={contract.status} /> : "-"}
      />
      <TableRow
        titleId='emailColumnHeader'
        value={emailAddress ?? "-"}
      />
      <TableRow
        titleId='dateColumnHeader'
        value={dayjs(created).format("DD/MM/YY")}
      />
      <TableRow
        titleId='locationColumnHeader'
        link={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.locations, `${location?.id}`])}
        value={location?.name ?? "-"}
      />
    </TableCard>
  )
}
