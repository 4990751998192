import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  Box, Step, StepLabel, Stepper, Typography
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { businessesApi, contractsApi, tariffsApi, usersApi } from "src/api";
import { useAppSelector } from "src/app/hooks";
import { store } from "src/app/store";
import AMPModal from "src/components/common/AMPModal";
import { PageHeader } from "src/components/common/PageHeader";
import { ContractsTable } from "src/components/contracts/ContractsTable";
import { ContractSortFieldEnum, ContractStatusManualEnum } from "src/enums/contracts";
import ability from "src/permissions/ability";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { ListOfTariffs } from "../../components/tariffs/ListOfTariffs";
import { CreateContractGeneralForm } from "../../components/forms/CreateContractGeneralForm";
import { ContractManualFormType } from "../../components/forms/formData/createManualContract";
import { RoleEnum, TariffsStatusEnum } from "../../enums";
import { UploadContract } from "./UploadContract";
import {
  createContractManuallySteps,
  initContractGeneral
} from "../../components/forms/formData/common/initialData";
import {
  ContractSearchRequestData,
  CreateManualContractRequest,
  ListParamsType,
  ReactLocationStateType
} from "src/types";
import {
  separatorSx,
  stepIconsSx,
  stepperSx
} from "../../components/contracts/CreationContract/CreationContract.sx";
import { AppRouteSpace, AppRouteEnum, isErrorObject } from "src/utils/helper";
import { uploadContractSx } from "./UploadContract/UploadContract.sx";
import { NotifyError } from "src/utils/notification";

type ContractsQueryTrigger = ReturnType<typeof contractsApi.useLazyGetContractsQuery>[0];
type BusinessesQueryTrigger = ReturnType<typeof businessesApi.useLazyGetBusinessesQuery>[0];
type ClientsQueryTrigger = ReturnType<typeof usersApi.useLazyGetUsersQuery>[0];
type CreateContractManuallyQueryTrigger = ReturnType<typeof contractsApi.useCreateManualContractMutation>[0];
type GetUserQueryTrigger = ReturnType<typeof usersApi.useLazyGetUserByIdQuery>[0];

interface DataType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [index: string]: any;
}

const handleGetContracts = (trigger: ContractsQueryTrigger, businessId?: number) => (async (tableData: ContractSearchRequestData, params: ListParamsType) => {
  store.dispatch(preloaderChangeStatus(true))
  const newData: DataType = {
    ...tableData,
    businessId: tableData.businessId ? tableData.businessId : businessId

  }
  Object.keys(tableData).map((item: string) => {
    (!newData[item] && item !== 'sortField' && item!== 'sortAsc') && delete newData[item]
  })
  const data: ContractSearchRequestData = {
    ...newData
  }
  try {
    const response = await trigger({ data, params }).unwrap()
    store.dispatch(preloaderChangeStatus(false))
    return response
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
});

const handleGetUserData = async (getUserInfo: GetUserQueryTrigger, userId: number, businessId: number) => {
  try {
    store.dispatch(preloaderChangeStatus(true));
    await getUserInfo({
      userId,
      businessId
    })
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
}

export const getBusinessesData = async (trigger: BusinessesQueryTrigger, size: number, page: number) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    await trigger({
      params: {
        size,
        page
      }
    })
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

export const getClientsData = async (
  trigger: ClientsQueryTrigger,
  authBusinessId: number | undefined,
  page: number,
  size: number,
  keyword?: string
) => {

  try {
    store.dispatch(preloaderChangeStatus(true))
    await trigger({
      data:
        {
          businessId: authBusinessId,
          contractExists: false,
          roles: [RoleEnum.DRIVER],
          keyword
        },
      params: {
        size,
        page
      }
    })
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

const handleCreateContract = (
  activeTariff: number,
  authBusinessId: number,
  generalInfo: ContractManualFormType,
  createContract: CreateContractManuallyQueryTrigger,
  changeStep: (id: number) => () => void
) => {
  return async () => {
    const data: CreateManualContractRequest = {
      tariffId: activeTariff,
      businessId: authBusinessId,
      contractCreatedAt: generalInfo?.contractCreatedAt,
      signedAt: generalInfo?.contractCreatedAt,
      operationalAt: generalInfo?.contractStatus === ContractStatusManualEnum.OPERATION ? (generalInfo?.operationalAt ?? null) : null,
      contractId: generalInfo?.contractId,
      contractStatus: generalInfo?.contractStatus,
      userId: +generalInfo?.userId,
    }
    try {
      store.dispatch(preloaderChangeStatus(true))
      await createContract(data)
      changeStep(2)()
    } catch (e) {
      console.log(e)
    } finally {
      store.dispatch(preloaderChangeStatus(false))
    }
  }
}

export const Contracts: FC = () => {
  const intl = useIntl();
  const { authInfo } = useAppSelector((state) => state.common);

  const [trigger, response] = contractsApi.useLazyGetContractsQuery();
  const [getTariffs, tariffs] = tariffsApi.useLazyGetTariffsQuery();
  const [getUserInfo, userInfo] = usersApi.useLazyGetUserByIdQuery()
  const [createContract, createContractResponse] = contractsApi.useCreateManualContractMutation();

  const [activeTariff, setActiveTariff] = useState<number>(0);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [generalInfo, setGeneralInfo] = useState<ContractManualFormType>(initContractGeneral)
  const [showAddContractModal, setShowAddContractModal] = useState(false);
  const [tableParams, setTableParams] = useState<ListParamsType>({
    page: 0,
    size: 100
  })
  
  const [filterParams, setFilterParams] = useState<ContractSearchRequestData>({
    businessId: 0,
    keyword: '',
    sortAsc: true,
    sortField: ContractSortFieldEnum.CREATED,
  })
  const manageContracts = ability.can('manage', 'contracts')
  const readContracts = ability.can('read', 'contracts')

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate()
  
  useEffect(() => {
    if(!readContracts){
      return navigate(AppRouteSpace([AppRouteEnum.app, AppRouteEnum.users, String(authInfo.id)]));
    }
    const currentState = location.state as ReactLocationStateType | undefined;
    const currentSearch = location.search
    if(currentState){
      setSearchParams({
        page: currentState?.page ?? currentSearch?.replace(/(\D+)(\d+)$/, "$2") ?? String(tableParams.page)
      })
      setTableParams({
        ...tableParams,
        page: +currentState?.page ?? +currentSearch?.replace(/(\D+)(\d+)$/, "$2") ?? tableParams.page
      })
      callHandler(filterParams, {
        ...tableParams,
        page: +currentState.page
      })
      return
    }
    Object.keys(authInfo) && callHandler(filterParams, tableParams)
  }, []);
  
  useEffect(() => {
    if(userInfo.data){
      getTariffs({data: {
        businessId: authInfo.business.id,
        status: TariffsStatusEnum.ACTIVE,
        country: userInfo.data.parkingAddress?.country,
        region: userInfo.data.parkingAddress?.region,
      }}).then(async (res) => {
        if (!res?.data?.content?.length && userInfo.data) {
          await getTariffs({data: {
            businessId: authInfo.business.id,
            status: TariffsStatusEnum.ACTIVE,
            country: userInfo.data.parkingAddress?.country,
            emptyRegion: true
          }})
        }
      })
      setActiveStep(1)
    }
  }, [generalInfo, userInfo])
  
  const changeStep = (step:number) => () => setActiveStep(step)

  
  const submitGeneralForm = (values: ContractManualFormType ) => {
    setGeneralInfo({
      ...values
    });
    handleGetUserData(getUserInfo, +values.userId, authInfo.business.id)
  }
  
  const handleShowAddContractModal = (status: boolean) =>
    () => {
      setShowAddContractModal(status);
      setActiveStep(0)
      if (!status) {
        setGeneralInfo(initContractGeneral)
        setActiveStep(0)
      }
    }

  const handleChange = (value: ContractSearchRequestData) => {
    setFilterParams(value)
    setTableParams({
      page: 0,
      size: tableParams.size
    })
    setSearchParams({})
    Object.keys(authInfo) && callHandler(value, {
      page: 0,
      size: tableParams.size
    })
  }

  const handleChangePage = (page: number) => {
    Object.keys(authInfo) && callHandler(filterParams, {
      page,
      size: tableParams.size
    })
    setTableParams({
      page,
      size: tableParams.size
    })
    setSearchParams({
      page: String(page)
    })
  }
  
  const handleSelectCard = (id: number) => {
    setActiveTariff(id)
  }

  const handleChangeRowsNumber = (e: ChangeEvent<HTMLInputElement>) => {
    Object.keys(authInfo) && callHandler(filterParams, {
      page: 0,
      size: +e.target.value || 100
    })
    setTableParams({
      page: 0,
      size: +e.target.value || 100
    })
  }
  
  const callHandler = handleGetContracts(trigger, authInfo.business && +authInfo.business.id)
  return (
    <Box>
      <PageHeader
        title={intl.formatMessage({ id: 'contracts' })}
        buttonText="addContract"
        onClick={handleShowAddContractModal(true)}
        onlyTitle={!manageContracts}
      />
      {response.data && (
        <ContractsTable
          businessId={authInfo.business && +authInfo.business.id}
          pageNumber={tableParams.page}
          data={response.data}
          size={tableParams.size}
          handleUpdateTableData={handleChange}
          handleChangePage={handleChangePage}
          handleChangeRowsNumber={handleChangeRowsNumber}
        />
      )}
      <AMPModal
        handleClose={handleShowAddContractModal(false)}
        open={showAddContractModal}
        title={intl.formatMessage({id: "createNewContract"})}
        type="md"
        fullWidth
        withoutPaddings
      >
        <Stepper
          activeStep={activeStep}
          sx={stepperSx}
          connector={<ArrowForwardIos sx={separatorSx}/>}
        >
          {createContractManuallySteps(intl).map((label) => (
            <Step
              sx={uploadContractSx.stepRoot}
              key={label.title}
            >
              <StepLabel
                sx={uploadContractSx.stepItem}
                StepIconProps={{
                  sx: stepIconsSx
                }}
              >
                <Typography
                  variant="h4"
                  lineHeight="1.2"
                  sx={uploadContractSx.stepTitle}
                >
                  {label.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={uploadContractSx.stepDescription}
                >
                  {label.description}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {
          (activeStep === 0) && (
            <CreateContractGeneralForm
              initialValues={generalInfo}
              handleSubmit={submitGeneralForm}
              handleClose={handleShowAddContractModal(false)}
              businessId={authInfo?.business?.id}
            />
          )
        }
        {
          (activeStep === 1 && tariffs.data) && (
            <ListOfTariffs
              activeItem={activeTariff}
              submitTitle="createContract"
              content={tariffs.data.content}
              handleSelectCard={handleSelectCard}
              changeStep={handleCreateContract(activeTariff,
                authInfo?.business?.id,
                generalInfo,
                createContract,
                changeStep)
              }
              handleClose={handleShowAddContractModal(false)}
              handleBack={changeStep(0)}
              disableSubmit={activeTariff === 0}
            />
          )
        }
        {activeStep === 2 && (
          <UploadContract
            handleClose={handleShowAddContractModal(false)}
            handleBack={changeStep(1)}
            contractId={createContractResponse?.data?.id}
            businessId={authInfo?.business?.id}
          />
        )}
      </AMPModal>
    </Box>
  )
};
