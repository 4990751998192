import { Logout } from "@mui/icons-material";
import { Box, Container, Grid, IconButton, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import AMPModal from "src/components/common/AMPModal";
import { ConfirmUserEmail } from "src/components/common/ConfirmUserEmail";
import { CreationContract } from "src/components/contracts/CreationContract";
import { SignatureContract } from "src/components/contracts/SignatureContract";
import { FeedbackForm } from "src/components/forms/FeedbackForm";
import { CheckIcon } from "src/components/icons/CheckIcon";
import { LogoBlack } from "src/components/icons/LogoBlack";
import { MailIcon } from "src/components/icons/MailIcon";
import { ContractStatusEnum, RegistrationStatusEnum, RoleEnum } from "src/enums";
import { clearAuthInfo } from "src/stores/common/commonSlice";
import {
  langSx,
  logoWrapperSx,
  modalButtonSx,
  modalLogOutButtonSx,
  stepLabelSx,
  stepperSx,
  stepSx,
  welcomeContentSx,
  welcomeSubtitleSx,
  welcomeTitleSx,
  wrapperSx
} from "./Welcome.sx";
import { AuthUserDTO, FeedbackTypeEnum } from "../../types";
import { LangSelect } from "../../components/common/LangSelect";
import { useNavigate } from "react-router-dom";
import { Installation } from "src/components/common/Installation";


export const Welcome: FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0)
  const [open, setOpen] = useState<boolean>(false)
  const { authInfo }: { authInfo: AuthUserDTO } = useAppSelector((state) => state.common)
  const intl = useIntl()
  const dialogTitle = intl.formatMessage({ id: 'feedback' })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleLogOut = () => {
    localStorage.clear()
    dispatch(clearAuthInfo())
  }

  const steps = [
    intl.formatMessage({ id: "registration" }),
    intl.formatMessage({ id: "creationContract" }),
    intl.formatMessage({ id: "signingContract" }),
    intl.formatMessage({ id: "installation" }),
    intl.formatMessage({ id: "operation" })
  ]

  useEffect(() => {
    if (authInfo.roles[0].role !== RoleEnum.DRIVER) {
      navigate(`/app/locations`)
    }
    if (authInfo.contract?.status === ContractStatusEnum.OPERATION) {
      navigate(`/app/chargers/${authInfo.chargePoint.id}`)
    }
  }, [])

  useEffect(() => {
    if (authInfo.registrationStatus !== RegistrationStatusEnum.ACTIVATED) {
      setActiveStep(0)
      return
    }
    if (!authInfo.contract) {
      setActiveStep(1)
      return
    }
    if (authInfo.contract.status === ContractStatusEnum.WAIT_FOR_SIGNATURE) {
      setActiveStep(2)
      return
    }
    if (authInfo.contract.status === ContractStatusEnum.INSTALLATION) {
      setActiveStep(3)
      return
    }
    if (authInfo.contract.status === ContractStatusEnum.OPERATION) {
      setActiveStep(4)
      return
    }
  }, [authInfo]);

  const handleClose = () => {
    setOpen(false);

  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <Grid
      container
      spacing={2}
      sx={wrapperSx}
    >
      <Grid
        item
        xs={5}
      >
        <Box sx={logoWrapperSx}>
          <LogoBlack />
        </Box>
        <Typography
          variant="h1"
          color="textPrimary"
          sx={welcomeTitleSx}
        >
          <FormattedMessage
            id="helloUser"
            values={{
              name: `${authInfo.firstName} ${authInfo.lastName}`
            }}
          />
        </Typography>
        <Typography
          variant="subtitle1"
          color="grey.500"
          sx={welcomeSubtitleSx}
        >
          <FormattedMessage
            id="customerArea"
          />
        </Typography>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={stepperSx}
        >
          {steps.map((step, index) => (
            <Step
              key={step}
              sx={stepSx}
            >
              <StepLabel
                StepIconComponent={CheckIcon}
              >
                <Typography
                  variant={activeStep === index ? 'h2' : 'h3'}
                  color="text.primary"
                  sx={stepLabelSx}
                >
                  {step}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid
        item
        xs={7}
        sx={welcomeContentSx}
      >
        <Container maxWidth="xs">
          {activeStep === 0 && <ConfirmUserEmail />}
          {activeStep === 1 && <CreationContract />}
          {activeStep === 2 && <SignatureContract />}
          {activeStep === 3 && <Installation />}
        </Container>
        {activeStep < 2 &&
          <IconButton
            onClick={handleLogOut}
            sx={modalLogOutButtonSx}
          >
            <Logout />
          </IconButton>
        }
        {activeStep < 2 &&
          <Box sx={langSx}>
            <LangSelect
              white={false}
              short
            />
          </Box>
        }
        <IconButton
          onClick={handleOpen}
          sx={modalButtonSx}
        >
          <MailIcon />
        </IconButton>
        <AMPModal
          handleClose={handleClose}
          open={open}
          title={dialogTitle}
          type="xs"
          fullWidth
        >
          <FeedbackForm
            type={FeedbackTypeEnum.HELP}
            handleClose={handleClose}
          />
        </AMPModal>
      </Grid>
    </Grid>
  );
}
