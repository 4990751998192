import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useAppDispatch } from "src/app/hooks";
import { VerifyEmailIcon } from "src/components/icons/VerifyEmailIcon";
import { clearAuthInfo } from "src/stores/common/commonSlice";
import { SubmitButton } from "../SubmitButton";
import { innerSx, textWrapperSx } from "./ConfirmUserEmail.sx";

export const ConfirmUserEmail: FC = () => {
  const dispatch = useAppDispatch() 
  const handleLogOut = () => {
    localStorage.clear()
    dispatch(clearAuthInfo())
  }
  return (
    <Box sx={innerSx}>
      <VerifyEmailIcon />
      <Box sx={textWrapperSx}>
        <Typography variant="subtitle1">
          <FormattedMessage id="verifyYourEmail" />
        </Typography>
      </Box>
      <SubmitButton
        text={'logOut'}
        handler={handleLogOut}
        align={'center'}
      />
    </Box>
  )
}
