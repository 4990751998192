import * as Yup from "yup";
import { parsePhoneNumber } from "libphonenumber-js";

export interface FeedbackType extends Record<string, unknown>{
  phone: string,
  message: string
}
export const feedbackSchema = Yup.object().shape({
  message: Yup.string().min(2).max(256).required('schemaRequiredMessage'),
  phone: Yup.string().test("valid", "incorrectPhoneFormat", function (phone) {
    try {
      return parsePhoneNumber(phone as string).isValid();
    } catch (e) {
      return false;
    }
  }),
})
