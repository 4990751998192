import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { transactionsApi } from "src/api";
import { DateSelect } from "src/components/common/DateSelect";
import { Table } from "src/components/tables/Table";
import { columnConfigTransactions } from "src/components/transactions/TransactionsTable/TransactionsTable.tableConfig";
import { DatesType } from "src/pages/Reports/Reports.types";
import { EmptyTable } from "../../tables/EmptyTable";
import { TransactionSearchRequestData } from "src/types";
import { ChargerTransactionCard } from "./ChargerTransactionCard";
import { TableRowCounter } from "../../tables/TableRowCounter";
import { TablePagination } from "../../tables/TablePagination";
import { rootSx, titleSx, selectWrapperSx, tableFooterSx } from "./ChargerTransactionsTable.sx";
import { ChargerTransactionsTableType } from "./ChargerTransactionsTable.types";
import { ResponsiveTable } from "../../common/ResponsiveTable";

export const ChargerTransactionsTable: FC<ChargerTransactionsTableType> = ({ businessId, chargerId }) => {
  const intl = useIntl()
  const title = intl.formatMessage({ id: 'transactions' })
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [getTransactions, transactions] = transactionsApi.useLazyGetTransactionsQuery()

  const [dates, setDates] = useState<DatesType>({
    allSessions: {
      from: undefined,
      to: dayjs().valueOf()
    }
  })
  
  const handlePerPageChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const val = +e.target.value
    if (typeof val === 'number') {
      setPerPage(val)
      setCurrentPage(0)
    }
  }
  
  const handleChangePage = (e: number) => {
    setCurrentPage(e)
  }

  const getData = async (data: TransactionSearchRequestData) => {
    await getTransactions({
      data,
      params: {size: perPage, page: currentPage}})
  }

  useEffect(() => {
    getData({
      businessId,
      chargerId,
      startFrom: dates.allSessions.from,
      startTo: dates.allSessions.to,
    })
  }, [dates, perPage, currentPage])

  const handleChangeDates = (
    newFrom: number,
    newTo: number,
    field: string
  ) => {
    return () => {
      setDates({
        [field]: {
          from: newFrom,
          to: newTo
        }
      })
    }
  }

  return (
    <Box sx={rootSx}>
      <Box sx={titleSx}>
        <Typography variant="subtitle1">{title}</Typography>
        <Box sx={selectWrapperSx}>
          <DateSelect
            handleTimeChange={handleChangeDates}
            field={Object.keys(dates)[0]}
          />
        </Box>
      </Box>
      <ResponsiveTable
        desktopElement={
          <Table
            sx={{
              border: 'none'
            }}
            rows={transactions.data?.content ?? []}
            hideFooter
            columns={columnConfigTransactions}
          />
        }
        responsiveElement={
          <>
            {transactions?.data?.content?.map(item => (
              <ChargerTransactionCard key={item?.id}
                {...item} />
            ))}
            {!transactions?.data?.content?.length && <EmptyTable />}
          </>
        }
      />
      <Box sx={tableFooterSx}>
        <TableRowCounter
          handleChangeRowsNumber={handlePerPageChange}
          defaultValue={perPage}
        />
        <TablePagination
          totalPages={transactions?.data?.totalPages ?? 0}
          page={currentPage}
          handleChangePage={handleChangePage}
        />
      </Box>
    </Box>
  )
}
