import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const openModalBtn: SxProps<Theme> = {
  mt: theme.spacing(4/8),
  width: "100%",
  borderRadius:theme.spacing(44/8),
  color: (theme: Theme) => theme.palette.common.white
}

export const headerSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    mb: 3
  }
}
