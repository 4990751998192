import { GridColumns } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import { convertTime } from 'src/utils/helper';

export const columnConfigTransactions: GridColumns = [
  {
    field: 'startTimestamp',
    renderHeader: (params) => <FormattedMessage id={"transactionStartColumnHeader"} />,
    flex: 1,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.startTimestamp && (new Date(params.row.startTimestamp)).toLocaleString(),
  },
  {
    field: 'stopTimestamp',
    renderHeader: (params) => <FormattedMessage id={"transactionEndColumnHeader"} />,
    flex: 1,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.stopTimestamp && (new Date(params.row.stopTimestamp)).toLocaleString(),
  },
  {
    field: 'chargingTime',
    renderHeader: (params) => <FormattedMessage id={"chargingTimeColumnHeader"} />,
    flex: 1,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.transactionSummary?.chargingTimeMinutes && convertTime(params.row.transactionSummary.chargingTimeMinutes),
  },
  
  {
    field: 'powerConsumed',
    renderHeader: (params) => <FormattedMessage id={"powerConsumedColumnHeader"} />,
    flex: 1,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.transactionSummary && (params.row.transactionSummary.wattsConsumed / 1000).toFixed(2),
  },
  {
    field: 'idTag',
    renderHeader: (params) => <FormattedMessage id={"rfid"} />,
    flex: 1,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row?.idTag?.idTag,
  },
]
