import { SelectChangeEvent } from "@mui/material";
import dayjs from "dayjs";
import { FC, useState } from "react";
import { Range } from "react-date-range";
import { useIntl } from "react-intl";
import { timeMap } from "src/components/forms/formData/common/initialData";
import { PickCustomPeriodForm } from "src/components/forms/PickCustomPeriodForm";
import { PickCustomPeriodFormReturnType } from "src/components/forms/PickCustomPeriodForm/PickCustomPeriodForm.types";
import AMPModal from "../AMPModal";
import { TimeSelect } from "../TimeSelect";
import { DateSelectType } from "./DateSelect.types";

export const DateSelect: FC<DateSelectType> = ({ field, handleTimeChange, contractLimit, handleShowOverlimit, handleHideOverlimit, dateFrom,disabled }) => {
  const intl = useIntl()

  const [currentTime, setCurrentTime] = useState(4)
  const [showCalendar, setShowCalendar] = useState(false)

  const handleShowCalendar = () => {
    setShowCalendar(true)
  }
  const handleHideCalendar = () => {
    setShowCalendar(false)
  }

  const dateRange: Range = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }

  const handleCustomClick = () => {
    handleShowCalendar()
  }

  const onSelect = (e: SelectChangeEvent) => {
    const value = Number(e.target.value)
    setCurrentTime(value)
    if (value !== 5 && field) {
      const dateFromContract = value === 6 ? dateFrom : timeMap(intl, handleCustomClick)[value]?.dateFrom
      handleTimeChange
        && handleTimeChange(
          dateFromContract ?? 0,
          dayjs().valueOf(),
          field)()
    }
    if (value === 6 && handleShowOverlimit) {
      handleShowOverlimit()
      return
    }
    handleHideOverlimit && handleHideOverlimit()
  }

  const onSubmitRange = () => {
    return (values: PickCustomPeriodFormReturnType) => {
      const endDate = dayjs(values.range.endDate).set('hour', 23).set('minute', 59).set('second', 59)
      handleTimeChange && field &&
        handleTimeChange(
          dayjs(values.range.startDate).valueOf(),
          endDate.valueOf(),
          field
        )()
      handleHideCalendar()
    }
  }
  return (
    <>
      <TimeSelect
        currentTime={currentTime}
        onChange={onSelect}
        onSubmitRange={onSubmitRange}
        showCalendar={showCalendar}
        handleCustomClick={handleCustomClick}
        handleShowCalendar={handleShowCalendar}
        handleHideCalendar={handleHideCalendar}
        handleShowOverlimit={handleShowOverlimit}
        contractLimit={contractLimit}
        disabled={disabled}
      />
      <AMPModal
        handleClose={handleHideCalendar}
        open={showCalendar}
        title={intl.formatMessage({ id: "customPeriod" })}
        type="md"
        fullWidth
        withoutPaddings
      >
        <PickCustomPeriodForm
          handleClose={handleHideCalendar}
          handleSubmit={onSubmitRange()}
          initialValues={{
            range: dateRange
          }}
        />
      </AMPModal>
    </>
  )
}