import { Box, Button, Typography } from "@mui/material";
import { FC, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/app/hooks";
import { RoleEnum } from "src/enums";
import { setActiveBusiness } from "src/stores/common/commonSlice";
import { AppRouteEnum, AppRouteSpace } from "src/utils/helper";
import { imageWrapperSx, linkButtonSx } from "./UserCell.sx";
import { UserCellType } from "./UserCell.types";
import { blankUserPhoto, managerRoles } from "../../../utils/consts";

export const UserCell: FC<UserCellType> = ({ data }) => {
  const location = useLocation()
  const isBusiness = data.roles[0].role === RoleEnum.BUSINESS
  const link = useMemo(() => {
    if(data.roles.length < 1){
      return
    }
    
    const isManager = managerRoles.includes(data.roles[0].role)
    
    if(isBusiness){
      return AppRouteSpace([AppRouteEnum.app, AppRouteEnum.users, AppRouteEnum.business, String(data.business.id)])
    }
    if(isManager) {
      return AppRouteSpace([AppRouteEnum.app, AppRouteEnum.technicalTeam, String(data.id)])
    }
    return AppRouteSpace([AppRouteEnum.app, AppRouteEnum.users, String(data.id)])
  }, [data.roles])
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleLocate = () => {
    if(data.business && link){
      dispatch(setActiveBusiness(data.business.id))
      navigate(link, {
        state: {
          page: location.search.replace(/(\D+)(\d+)$/, '$2')
        }
      })
    }
  }

  return (
    <Box>
      <Button
        onClick={handleLocate}
        sx={linkButtonSx}
      >
        <Box sx={imageWrapperSx}>
          <img
            src={data.avatar?.url || blankUserPhoto}
            alt={`${data.firstName} ${data.lastName}`}
          />
        </Box>
        <Typography
          variant="subtitle2"
          color="primary.main"
        >
          {!isBusiness
            ? `${data.firstName} ${data.lastName}`
            : `${data.business.name}`  
          }
        </Typography>
      </Button>
    </Box>
  )
}
